import "./locationPageStyles.css";
import LocationPageImage from "../../assets/images/park.png";
import SnowflakeIcon from "../../assets/images/snowflakeGreen2x.svg";
import MapImage from "../../assets/images/map.png";
import SideBarMenu from "../../components/sideBarMEnu/sideBarMenu";
import Header from "../../components/Header/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useRef, useState } from "react";
import LocationMap from "../../components/locationMap/locationMap";
import SwiperControler from "../../components/swipperControler/swiperControler";

const LocationPage = () => {
  const [swiper, setSwiper] = useState();
  const [index, setIndex] = useState(1);
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);
  return (
    <div className="locationPage">
      <SideBarMenu />
      <Header />

      <main className="mainLocation">
        <div className="textContent">
          <h1 className="titleTxt">
            <span>PARKS &</span>
            <span>TRANSPORT</span>
            <span>-ATION</span>
          </h1>
          <div className="desc_block">
            <h2>0 mins</h2>
            <p className="locationDescriptionTxt">
              <span className="boldTxt"> Walk to our 800m2 garden. </span>
              Because it’s our garden in our own yard.
            </p>
          </div>

          <div className="desc_block">
            <h2>5 mins</h2>
            <p className="locationDescriptionTxt">
              <span className="boldTxt"> Walk to Central Park. </span>
              If our garden is not enough for you.
            </p>
          </div>

          <div className="desc_block">
            <h2>7 mins</h2>
            <p className="locationDescriptionTxt">
              <span className="boldTxt"> Walk to Delisi Metro. </span>
              For transport and stuff.
            </p>
          </div>
        </div>
        <div className="imageContainer">
          <img
            src={LocationPageImage}
            alt="LocationPageImage"
            className="cover"
          />

          <img src={SnowflakeIcon} className="snowflakeIcon" alt="Snowflake" />
        </div>
        <img src={MapImage} className="map-image" alt="map-shortcut" />
        <div className="location-foter">
          <p style={{ textAlign: "end" }}>
            <span className="boldTxt">Hive Co.Working</span>
            <span className="location">
              {" "}
              13 Mikheil Tamarashvili. Tbilisi 0219{" "}
            </span>
            <LocationMap />
          </p>
        </div>
      </main>

      <div className="swiperWrap">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          modules={[Navigation]}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          onSlideChange={(swiper) => setIndex(swiper.activeIndex + 1)}
          onSwiper={setSwiper}
        >
          <SwiperSlide>
            <div className="desc_block">
              <h2>0 mins</h2>
              <p className="locationDescriptionTxt">
                <span className="boldTxt"> Walk to our 800m2 garden. </span>
                <span className="transparant-text">
                  Because it’s our garden in our own yard.
                </span>
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="desc_block">
              <h2>5 mins</h2>
              <p className="locationDescriptionTxt">
                <span className="boldTxt"> Walk to Central Park. </span>
                <span className="transparant-text">
                  If our garden is not enough for you.
                </span>
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="desc_block">
              <h2>7 mins</h2>
              <p className="locationDescriptionTxt">
                <span className="boldTxt"> Walk to Delisi Metro. </span>
                <span className="transparant-text">
                  For transport and stuff.
                </span>
              </p>
            </div>
          </SwiperSlide>
        </Swiper>

        <SwiperControler nextRef={nextRef} prevRef={prevRef}>
          0{index}/03
        </SwiperControler>
      </div>
    </div>
  );
};

export default LocationPage;
