import React from "react";
import "./FacilitiesFirstContent.styles.css";
import backImage from "../../assets/images/break-room.png";
import innerImage from "../../assets/images/small-working-rooms.png";
import NextButtonWithArrow from "../nextButtonWithArrow/nextButtonWithArrow";

export const FacFirstData = [
  {
    title: "Telephone Booths.",
    code: "",
    description: "Soundproofed one-person meeting spaces.",
  },
  {
    title: "Kitchens + Dinning.",
    code: "",
    description: "Kitchens. Separate spaces for lunch.",
  },
  {
    title: "Game & Relax zones.",
    code: "",
    description: "Cosy nooks and entertainment zones.",
  },
];

const FacilitiesFirstContent = ({ onClick }) => {
  return (
    <div className="fac_wrapper">
      <div className="fac_container">
        <h1 class="first_title">
          5,500<sup>m2</sup>
        </h1>
        <span className="first_subtitle">to meet your work/life balance</span>
        <ol className="first_list" type="A">
          {FacFirstData.map((each, i) => {
            return (
              <li key={i} className="first_listItem">
                <span>{each.title}</span>
                <span className="first_desc"> {each.description}</span>
                {/* <span> {each.code}</span> */}
              </li>
            );
          })}
        </ol>
        <NextButtonWithArrow onClick={onClick}>Next</NextButtonWithArrow>
      </div>

      <div className="first_imageContainer">
        <img src={backImage} alt="" className="first_cover" />
        <img src={innerImage} alt="" className="first_inner_image" />
      </div>
    </div>
  );
};

export default FacilitiesFirstContent;
