import { ReactComponent as CloseSvg } from "../../assets/images/close.svg";

import "./modal.css";

const Modal = ({ isVissible, message, onClose }) => {
  return (
    isVissible && (
      <div className="overlay">
        <div className="dialog">
          <span className="dialog__close" onClick={onClose}>
            <CloseSvg />
          </span>
          <p className="dialog__message">{message}</p>
        </div>
      </div>
    )
  );
};

export default Modal;
