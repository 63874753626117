import React, { useState } from "react";
import axios from "axios";

// components
import Header from "../../components/Header/Header";
import Modal from "../../components/Modal/modal";
import LocationMap from "../../components/locationMap/locationMap";
import SideBarMenu from "../../components/sideBarMEnu/sideBarMenu";

// assets
import ContactPageImg from "../../assets/images/contactPageImg.png";
import MobileContactPageImg from "../../assets/images/mob-mobile-phones.png";
import InstagramIcon from "../../assets/images/Iconawesome-instagram.svg";
import FacebookIcon from "../../assets/images/Iconawesome-facebook-f.svg";
import SnowflakeIcon from "../../assets/images/snowflakeGreen2x.svg";
import SnowflakeIcon2 from "../../assets/images/snowflakeRedSmall.svg";
import LinkedInIcon from "../../assets/images/white-linkedin-icon.svg";

import "./contactPageStyles.css";

const ContactPage = () => {
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const onSubbmitPress = () => {
    axios
      .post("https://api.dev.hiveco.space/api/v1/contact", {
        name,
        email,
        message,
      })
      .then((response) => {
        // alert(response?.data?.message);
        setShowModal(true);
      })
      .catch((error) => {
        // console.log("error->", error);
        alert(error?.response?.data?.errors[0]?.msg);
      });
    setName("");
    setEmail("");
    setMessage("");
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="contactPage">
      <SideBarMenu />
      <Header />
      <Modal
        isVissible={showModal}
        message="MESSAGE SENT"
        onClose={() => setShowModal(false)}
      />

      <main className="contactMainContent">
        <img src={SnowflakeIcon2} className="SnowflakeSmall" alt="Snowflake" />

        <div className="contentWrapper">
          <div className="textsContainer">
            <h1 className="contactTitleTxt">
              GET IN
              <br /> TOUCH
            </h1>

            <div className="inputContainer">
              <div className="nameInputsCont">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  className="inputStyle nameInput"
                />

                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="inputStyle nameInput"
                />
              </div>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
                className="inputStyle messageInput"
              />
            </div>
            <div className="btnContainer">
              <div className="centerAlign">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={checked}
                  onChange={handleChange}
                />
                <span className="spamTxt">Dont spam me.</span>
              </div>
              <button className="messageBtn" onClick={onSubbmitPress}>
                Message
              </button>
            </div>
            <div className="textCont">
              <p className="descTxt">
                <span className="boldTxt">Hive Coworking.</span>
                <br />
                13 Mikheil Tamarashvili. Tbilisi
                <span className="boldTxt"> 0162</span>
              </p>
              <div className="textCont__contact">
                <span className="boldTxt">+995 511 105 500</span>
                <p className="descTxt">Hello@hiveco.space</p>
              </div>
            </div>
            <div className="social-row">
              <div className="socialLogosContainer">
                <a
                  target="_blank"
                  href="https://www.instagram.com/hive.co.working/"
                >
                  <img
                    src={InstagramIcon}
                    className="socialLogo"
                    alt="social"
                  />
                </a>
              </div>
              <div className="socialLogosContainer">
                <a target="_blank" href="https://www.facebook.com/Hiveco.space">
                  <img src={FacebookIcon} className="socialLogo" alt="social" />
                </a>
              </div>
              <div className="socialLogosContainer">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/hivee-coworking/?viewAsMember=true"
                >
                  <img src={LinkedInIcon} className="socialLogo" alt="social" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="ContactPageImgContainer">
          <img
            src={ContactPageImg}
            className="contactPageImage"
            alt="contactPageImage"
          />
          <img
            src={MobileContactPageImg}
            className="contactPageImage contactPageImage--mobile"
            alt="contactPageImage"
          />
          <img
            src={SnowflakeIcon}
            className="contactSnowflakeIcon"
            alt="Snowflake"
          />
        </div>

        <div className="mobile-contact">
          <p>
            <span className="boldTxt">Hive Coworking.</span> <br />
            <span className="darker">
              13 Mikheil Tamarashvili. Tbilisi 0162
            </span>
            <br />
            <LocationMap />
          </p>
          <div className="mobile-contact__contacts">
            <a
              className="contact__link phone"
              href="tel:+995511105500"
              target="_blank"
              rel="noreferrer"
            >
              +995 511 10 55 00
            </a>

            <a
              className="contact__link darker"
              href="mailto:Hello@hiveco.space"
              target="_blank"
              rel="noreferrer"
            >
              Hello@hiveco.space
            </a>
          </div>
          <div className="mobile-social-row">
            <div className="socialLogosContainer">
              <a
                target="_blank"
                href="https://www.instagram.com/hive.co.working/"
              >
                <img src={InstagramIcon} className="socialLogo" alt="social" />
              </a>
            </div>
            <div className="socialLogosContainer">
              <a target="_blank" href="https://www.facebook.com/Hiveco.space">
                <img src={FacebookIcon} className="socialLogo" alt="social" />
              </a>
            </div>
            <div className="socialLogosContainer">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/hivee-coworking/?viewAsMember=true"
              >
                <img src={LinkedInIcon} className="socialLogo" alt="social" />
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactPage;
