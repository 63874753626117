import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

/** Pages */
import LandingPage from "../../pages/landingPage/landingPage";
import FacilitiesPage from "../../pages/facilitiesPage/facilitiesPage";
import LocationPage from "../../pages/locationPage/locationPage";
import RatesPage from "../../pages/ratesPage/ratesPage";
import ContactPage from "../../pages/contactPage/contactPage";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/facilities" element={<FacilitiesPage />} />
      <Route path="/location" element={<LocationPage />} />
      <Route path="/services" element={<RatesPage />} />
      <Route path="/contact" element={<ContactPage />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRouter;
