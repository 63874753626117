import "./ratesPageStyles.css";
import SideBarMenu from "../../components/sideBarMEnu/sideBarMenu";
import Header from "../../components/Header/Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";
import ConferenceRoomImage from "../../assets/images/service-conference-room.png";
import DedicatedDeskImage from "../../assets/images/dedicated-desk.png";
import FlexDeskImage from "../../assets/images/flex-desk.png";
import PrivateOfficeImage from "../../assets/images/service-private-office.png";
import MeetingRoomImage from "../../assets/images/service-meeting-room.png";
import NextButtonWithArrow from "../../components/nextButtonWithArrow/nextButtonWithArrow";
import { ReactComponent as SliderCountBoxIcon } from "../../assets/images/green-square.svg";
import { ratesData, viewData, VIEWS } from "./ratesData";
import GelSvg from "../../components/assets/gel";

const settings = {
  infinite: false,
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 2000,
  arrows: false,
};

function RatesPage() {
  const [currentView, setCurrentView] = useState(VIEWS.membership);
  const [currentSliderIndex, setCurrentSliderIndex] = useState(1);
  const sliderRef = useRef();

  const slideNext = () => {
    sliderRef.current.slickNext();
  };

  const slidePrev = () => {
    sliderRef.current.slickPrev();
  };

  const changeView = () => {
    setCurrentView((view) => {
      if (view === VIEWS.membership) {
        return VIEWS.services;
      } else {
        return VIEWS.membership;
      }
    });
  };

  const handleSlideChangeOnBoxClick = (slideIndex) => {
    sliderRef.current.slickGoTo(slideIndex);
  };

  const isMembershipView = currentView === VIEWS.membership;

  return (
    <div className="ratesPage">
      <SideBarMenu />
      <Header />

      <main className="ratesMainContent">
        <ViewContent
          data={viewData[currentView]}
          hasContainedBtn={isMembershipView}
          onClick={changeView}
        />
        <div className="rates-cards">
          {currentView === VIEWS.services ? (
            <>
              <DeskRateCard
                data={ratesData[2]}
                img={PrivateOfficeImage}
                isTall
              />
              <div className="rates-cards__row">
                <DeskRateCard data={ratesData[3]} img={MeetingRoomImage} />
                <DeskRateCard data={ratesData[4]} img={ConferenceRoomImage} />
              </div>
            </>
          ) : (
            <>
              <DeskRateCard data={ratesData[0]} img={FlexDeskImage} isTall />
              <DeskRateCard
                data={ratesData[1]}
                img={DedicatedDeskImage}
                isTall
              />
            </>
          )}
        </div>
      </main>
      <div className="mobile-rates-main-content">
        <ViewContent
          data={ratesData[currentSliderIndex - 1 || 0]?.mobileViewData}
        />
        <div className="rate-slider-container">
          <Slider
            ref={sliderRef}
            afterChange={(i) => setCurrentSliderIndex(i + 1)}
            {...settings}
          >
            {ratesData.map((data) => (
              <RateCard {...data} />
            ))}
          </Slider>
        </div>
        <div className="slider-box-container">
          {["A", "B", "C", "D", "E"].map((char, i) => (
            <SliderIndexBox
              isActive={currentSliderIndex === i + 1}
              onClick={() => handleSlideChangeOnBoxClick(i)}
            >
              {char}
            </SliderIndexBox>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RatesPage;

const SliderIndexBox = ({ isActive, onClick, children }) => (
  <div className={`slider-box ${isActive ? "active" : ""}`} onClick={onClick}>
    <p className="slider-box__text">{children}</p>
    <SliderCountBoxIcon />
  </div>
);

const ViewContent = ({ data, onClick = () => {} }) => (
  <div className="rates-description">
    <div className="rates-description__content-container">
      <h1 className="rates-description__title">{data.title}</h1>
      <h4 className="rates-description__subTitle">{data.subTitle}</h4>
      <p className="rates-description__desc">{data.desc}</p>
      {/* <span className="boldTxt">{data?.tag}</span> */}
      <NextButtonWithArrow onClick={onClick}>
        {data.buttonText}
      </NextButtonWithArrow>
    </div>
  </div>
);

const DeskRateCard = ({
  img,
  isTall,
  data: { title, description, tag, price, priceIn, hoverData },
}) => (
  <div className={`rate-card ${isTall ? "rate-card--tall" : ""}`}>
    <div className="rate-card__image-container">
      <img src={img} alt="conference room" />
    </div>
    <div className="rate-card__content-container">
      <h2 className="rate-card__title">{title}</h2>
      <span className="rate-card__desc">{description}</span>
      {/* <span className="boldTxt">{tag}</span> */}
      <div className="rate-card__price">
        <CardPrice price={price} />
        <sup>
          <GelSvg color="#ccfd34" />
          {priceIn}
        </sup>
      </div>
    </div>
    <div className="rate-card-hover">
      {hoverData && <RateCardHoverContent data={hoverData} />}
    </div>
  </div>
);

const RateCard = ({
  title,
  price,
  priceIn,
  description,
  tag,
  image,
  hoverData = null,
}) => {
  const [isHoverVissible, setIsHoverVissible] = useState(false);

  const toggleHover = () => {
    setIsHoverVissible((state) => !state);
  };

  return (
    <div className="rate-card" onClick={toggleHover}>
      <div className="rate-card__image-container">
        <img src={image} alt="conference room" />
      </div>
      <div className="rate-card__content-container">
        <h2 className="rate-card__title">{title}</h2>
        <span className="rate-card__desc">{description}</span>
        {/* <span className="boldTxt">{tag}</span> */}
        <div className="rate-card__price">
          <CardPrice price={price} />
          <sup>
            <GelSvg color="#ccfd34" />
            {priceIn}
          </sup>{" "}
        </div>
      </div>
      <div className="rate-card-hover rate-card-hover--centered">
        {hoverData && isHoverVissible && (
          <RateCardHoverContent data={hoverData} />
        )}
      </div>
    </div>
  );
};

const CardPrice = ({ price }) => {
  return price === "call" ? (
    <a className="call-price" href="tel:+995511105500" target="_blank" rel="noreferrer">
      Call
    </a>
  ) : (
    price
  );
};

const RateCardHoverContent = ({ data }) => (
  <>
    {data.map(({ title, prices }) => (
      <div className="card-list-container">
        {title && <p className="card-list-title">{title}</p>}
        <ul>
          {prices.map(({ title, price }) => (
            <li className="card-list-item">
              <p className="card-list-item__title">{title}</p>
              <p className="card-list-item__price">
                {price}{" "}
                <sup>
                  <GelSvg width={9.11} height={10.2} />
                </sup>
              </p>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </>
);
