import "./landingPageStyles.css";
import LandingPageImage from "../../assets/images/home-cover.png";
import SnowflakeIcon from "../../assets/images/snoflake-md-green.svg";
import SideBarMenu from "../../components/sideBarMEnu/sideBarMenu";
import Header from "../../components/Header/Header";

const LandingPage = () => {
  return (
    <div className="landingPage">
      <Header />
      <SideBarMenu />

      <main className="mainContent">
        <div className="textsContainer">
          <h2 className="topTxt">work from</h2>
          <h1 className="landingTitleTxt">
            HO
            <br />
            ME
          </h1>
          <h3 className="welcomeTxt">
            Hive is the biggest co-working space in Tbilisi.
          </h3>
          <p className="landingDescriptionTxt">
            Carefully conceptualised and handcrafted to meet the need of anyone.
            Whether you’re a freelancer, a remote worker, a start-up or a big
            company, Hive is an ideal place for you. It can accommodate your
            every need and even more. Hive co-working space will be opening
            soon.
          </p>
        </div>

        <div className="LandingPageImgContainer">
          <img
            src={LandingPageImage}
            className="LandingPageImg"
            alt="LandingPageImage"
          />
          <img
            src={SnowflakeIcon}
            className="LandingSnowflakeIcon"
            alt="Snowflake"
          />
        </div>
      </main>
    </div>
  );
};

export default LandingPage;
