import "./doubleArrow.styles.css";

export const DoubleArrow = ({ isArrowLeft }) => (
  <svg
    width="67"
    height="45"
    viewBox="0 0 67 45"
    fill="none"
    style={{
      transform: isArrowLeft ? "rotateZ(180deg)" : "none"
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arrow-double 1" clip-path="url(#clip0_708_2)">
      <g id="arrow">
        <path
          id="Path 9"
          d="M-4.57764e-05 22.354L60.679 22.354"
          stroke="#CCFD34"
        />
        <path
          id="Path 10"
          d="M39 0.354004L61 22.354L39 44.354"
          stroke="#CCFD34"
        />
      </g>
      <g id="arrow-head">
        <path
          id="Path 15"
          d="M44 0.354004L66 22.354L44 44.354"
          stroke="#CCFD34"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_708_2">
        <rect width="66.707" height="44.707" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DoubleArrow;
