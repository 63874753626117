import React from "react";
import "./socials.css";
import fb from "../../assets/images/fb.svg";
import ig from "../../assets/images/ig.svg";
import ln from "../../assets/images/ln.svg";

const Socials = () => {
  return (
    <div className="s_container">
      <a
        href="https://www.facebook.com/Hiveco.space"
        target="_blank"
        rel="noreferrer"
      >
        <img src={fb} alt="fb logo" />
      </a>
      <a
        href="https://www.instagram.com/hive.co.working"
        target="_blank"
        rel="noreferrer"
      >
        <img src={ig} alt="ig logo" />
      </a>

      <a
        href="https://www.linkedin.com/company/hivee-coworking/?viewAsMember=true"
        target="_blank"
        rel="noreferrer"
      >
        <img src={ln} alt="ig logo" />
      </a>
      {/* <a href="www.fb.com">
        <FaYoutube />
      </a>
      <a href="www.fb.com">
        <FaTiktok />
      </a> */}
    </div>
  );
};

export default Socials;
