import "./App.css";
import AppRouter from "./routes/appRouter/index";

function App() {
  return (
      <AppRouter />
  );
}

export default App;
