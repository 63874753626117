import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Socials from "../Socials/Socials";
import "./headerStyles.css";
import CloseMenu from "../../assets/images/close_mobile.svg";
import MenuIcon from "../../assets/images/menu_mobile.svg";
import H from "../../assets/images/h.svg";
import VE from "../../assets/images/ve.svg";

const Header = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [width, setWidth] = useState("2px");
  const { pathname } = useLocation();

  const toggleMenuHandler = () => {
    setIsMenuOpened((prev) => !prev);
  };

  useEffect(() => {
    switch (pathname) {
      case "/":
        setWidth("2px");
        break;
      case "/facilities":
        setWidth("14px");
        break;
      case "/location":
        setWidth("26px");
        break;
      case "/rates":
        setWidth("38px");
        break;
      case "/contact":
        setWidth("50px");
        break;
      default:
        setWidth("2px");
    }
  }, [pathname]);

  return (
    <header className="mobileMenu">
      <Link to="/" className="logo">
        <img src={H} alt="H" className="img" />
        <div className="iBlock" style={{ width: width }}></div>
        <img src={VE} alt="VE" className="img" />
      </Link>
      <img
        src={MenuIcon}
        alt="burger icon"
        className="img"
        onClick={toggleMenuHandler}
      />

      {isMenuOpened && (
        <div className="mobileNav">
          <img
            src={CloseMenu}
            alt="close"
            onClick={toggleMenuHandler}
            className="close"
          />

          <nav className="mobileNavigation" onClick={toggleMenuHandler}>
            <Link to="/facilities" className="navLink">
              FACILI- TIES
            </Link>
            <Link to="/location" className="navLink">
              LOCATION
            </Link>
            <Link to="/services" className="navLink">
              SERVICES
            </Link>
            <Link to="/contact" className="navLink">
              CONTACT
            </Link>
          </nav>

          <p className="headerContact">
            <span>Hive Coworking</span><br/> 13 Mikheil Tamarashvili. Tbilisi 0162 &nbsp;
            <br/>
            <a
              href="https://www.google.com/maps/dir/41.721373,44.7466408/13+Mikheil+Tamarashvili+St,+T'bilisi/@41.7212584,44.746422,417m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x404473176aebeb7b:0xdeadb1ab566c5800!2m2!1d44.7462121!2d41.7216461"
              target="_blank"
              rel="noreferrer"
            >
              OPEN MAPS
            </a>
          </p>

          <div className="contacts">
            <a
              href="tel:+995511105500"
              target="_blank"
              rel="noreferrer"
              className="tel"
            >
              +995 511 105 500
            </a>
            <a
              href="mailto:Hello@hiveco.space"
              target="_blank"
              rel="noreferrer"
              className="mail"
            >
              Hello@hiveco.space
            </a>
          </div>

          <Socials />
        </div>
      )}
    </header>
  );
};

export default Header;
