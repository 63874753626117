import { useEffect, useState } from "react";

const useDeviceWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width < 992;

  const changeWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return isMobile;
};

export default useDeviceWidth;
