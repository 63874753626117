import { ReactComponent as ArrowDirectionIcon } from "../../assets/images/arrow-direction.svg";

import "./locationMap.css";

const LocationMap = () => (
  <a
    className="contact-map"
    target="_blank"
    href="https://www.google.com/maps/dir/41.721373,44.7466408/13+Mikheil+Tamarashvili+St,+T'bilisi/@41.7212584,44.746422,636m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x404473176aebeb7b:0xdeadb1ab566c5800!2m2!1d44.7462121!2d41.7216461"
  >
    open maps
    <ArrowDirectionIcon />
  </a>
);

export default LocationMap;
