const GelSvg = ({ width = 10, height = 13, color = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 10 13"
  >
    <path
      id="Path_3547"
      data-name="Path 3547"
      d="M5.793-11.256a6.475,6.475,0,0,1,.845.065V-12.8h.626v1.771a4.475,4.475,0,0,1,1.9,1.121A5.313,5.313,0,0,1,10.4-7.966a7.038,7.038,0,0,1,.43,2.494H9.8a6.547,6.547,0,0,0-.634-2.99,3.339,3.339,0,0,0-1.9-1.706v4.7H6.638V-10.33a4.634,4.634,0,0,0-.845-.081,3.843,3.843,0,0,0-.767.065v4.875h-.61v-4.7a3.243,3.243,0,0,0-1.925,1.69,6.656,6.656,0,0,0-.626,3.006,5.289,5.289,0,0,0,.548,2.4A4.288,4.288,0,0,0,3.97-1.336a4.209,4.209,0,0,0,2.308.642H9.533V.2H1.8V-.661H3.68V-.726a3.782,3.782,0,0,1-1.557-1.1,5.02,5.02,0,0,1-.97-1.747A6.608,6.608,0,0,1,.832-5.618a6.646,6.646,0,0,1,.415-2.324,5.442,5.442,0,0,1,1.228-1.95,4.372,4.372,0,0,1,1.941-1.154V-12.8h.61v1.609A5.824,5.824,0,0,1,5.793-11.256Z"
      transform="translate(-0.832 12.8)"
      fill={color}
    />
  </svg>
);

export default GelSvg;
