import React, { useState } from "react";
import "./facilities.style.css";
import Header from "../../components/Header/Header";
import SideBarMenu from "../../components/sideBarMEnu/sideBarMenu";
import FacilitiesFirstContent from "../../components/FacilitiesFirstContent/FacilitiesFirstContent";
import FacilitiesSecondContent from "../../components/FacilitiesSecondContent/FacilitiesSecondContent";
import { ReactComponent as RedFlake } from "../../assets/images/snowflakeRedSmall.svg";
import FacilitiesMobile from "../../components/FacilitiesMobile/FacilitiesMobile";
import useDeviceWidth from "../../hooks/useDeviceWidth/useDeviceWidth";
const FIRST_CONTENT = 1;
const SECOND_CONTENT = 2;

const FacilitiesPage = () => {
  const [content, setContent] = useState(FIRST_CONTENT);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useDeviceWidth();

  const handleStateChange = () => {
    setIsLoading(true);

    setTimeout(() => {
      content === SECOND_CONTENT
        ? setContent(FIRST_CONTENT)
        : setContent(SECOND_CONTENT);
    }, 500);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div className="facilitiesPage">
      {!isMobile ? (
        <>
          <SideBarMenu />
          <div className={`loader ${isLoading ? "active" : ""}`}></div>

          <div className="facilities">
            {content === FIRST_CONTENT ? (
              <FacilitiesFirstContent onClick={handleStateChange} />
            ) : (
              <FacilitiesSecondContent onClick={handleStateChange} />
            )}
          </div>
        </>
      ) : (
        <>
          <Header />
          <FacilitiesMobile />
        </>
      )}

      <RedFlake className="green_flake" />
    </div>
  );
};

export default FacilitiesPage;
