import React, { useEffect, useRef, useState } from "react";
import "./FacilitiesMobile.styles.css";
import firstSlideImage from "../../assets/images/facilitiesOnePage.png";
import mobileFirstSlideImage from "../../assets/images/mobile-kitchen.png";

import secondSlideImage from "../../assets/images/facilitiesTwoPage.png";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FacFirstData } from "../FacilitiesFirstContent/FacilitiesFirstContent";
import { ReactComponent as RedFlake } from "../../assets/images/snowflakeRedSmall.svg";
import { FacSecondData } from "../FacilitiesSecondContent/FacilitiesSecondContent";
import innerImage from "../../assets/images/basketball.png";
import partyImage from "../../assets/images/party.png";

import "swiper/css";
import "swiper/css/navigation";
import SwiperControler from "../swipperControler/swiperControler";

const swiperContent = [FacFirstData, FacSecondData];

const DATA = [
  {
    cover: firstSlideImage,
    boxTitle: "A",
    title: "5,500",
    subtitle: "to meet your work/relax balance.",
    id: 0,
    description: {
      title: "Roof Terrace.",
      desc: "Slow-carb vexillologist whatever fixie lumbersexual semiotics humblebrag, JOMO seitan messenger bag skateboard butcher portland sus raclette.",
      code: "",
    },
    secondImage: null,
  },
  {
    cover: partyImage,
    boxTitle: "B",
    title: "WAY UP TOP",
    subtitle: "Turns out, size does matter.",
    id: 1,
    description: {
      title: "Chill zones & Nooks.",
      desc: "Echo park deep v cred lumbersexual tonx tousled. Vexillologist pug vape readymade kitsch leggings copper mug activated charcoal gluten-free ethical. ",
      code: "",
    },
    secondImage: innerImage,
  },
];

const firstSlideData = DATA[0];
const secondSlideData = DATA[1];

const FacilitiesMobile = () => {
  const [swiperRef, setSwiperRef] = useState();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const currentElement = document.querySelector(".fac_content");
    currentElement.classList.add("flash");

    const timeoutId = setTimeout(() => {
      currentElement.classList.remove("flash");
    }, 750);

    return () => {
      clearTimeout(timeoutId);
      currentElement.classList.remove("flash");
    };
  }, [index]);

  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiperRef) {
      swiperRef.params.navigation.prevEl = prevRef.current;
      swiperRef.params.navigation.nextEl = nextRef.current;
      swiperRef.navigation.init();
      swiperRef.navigation.update();
    }
  }, [swiperRef]);

  return (
    <div className="fac_mobile">
      <Swiper
        modules={[Pagination, Navigation]}
        spaceBetween={30}
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
        onSwiper={setSwiperRef}
      >
        <SwiperSlide>
          <div className="fac_mobile_cover">
            <img
              src={firstSlideData.cover}
              alt="cover"
              className="fac_page_cover first"
            />
            <img
              src={mobileFirstSlideImage}
              alt="cover"
              className="fac_page_cover fac_page_cover--mobile"
            />
            <img
              src={firstSlideData.secondImage}
              alt=""
              className="fac_mobile_cover_second"
            />
            <RedFlake className="green_snowFlake" />
            <div className="fac_mobile_head">
              <h1 className="fac_mobile_title fac_mobile_title--big">
                {firstSlideData.title}
                <sup>m2</sup>
              </h1>
              <span className="fac_mobile_subtitle">
                {firstSlideData.subtitle}
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="fac_mobile_cover second-cover">
            <img
              src={secondSlideData.cover}
              alt="cover"
              className="fac_page_cover second"
            />
            <img
              src={secondSlideData.secondImage}
              alt="cover"
              className="fac_page_cover_second_image"
            />
            <div className="fac_mobile_head">
              <h1 className="fac_mobile_title">{secondSlideData.title}</h1>
              <span className="fac_mobile_subtitle">
                {secondSlideData.subtitle}
              </span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="fac_content">
        <ol className="first_list">
          {swiperContent[index].map((each, i) => {
            return (
              <li key={i} className="first_listItem" type="A">
                <span>{each.title}</span>
                <span className="first_desc"> {each.description}</span>
                {/* <span> {each.code}</span> */}
              </li>
            );
          })}
        </ol>
      </div>

      <div className="fac_pagination">
        <SwiperControler nextRef={nextRef} prevRef={prevRef}>
          next
        </SwiperControler>
        {/* {DATA.map((each) => (
          <div
            key={each.id}
            className={`fac_page ${index === each.id ? "active" : ""}`}
            onClick={() => {
              swiperRef.current.slideTo(each.id);
            }}
          >
            <span>{each.boxTitle}</span>
            <SliderCountBoxIcon className="fac_page_back" />
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default FacilitiesMobile;
