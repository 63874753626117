import FlexDeskImage from "../../assets/images/service-meeting-room.png";
import MobileFlexDeskImage from "../../assets/images/card_a.png";
import MobileDedicatedDeskImage from "../../assets/images/card_b.png";
import MobilePrivateOfficeImage from "../../assets/images/card_c.png";
import MeetingRoomImage from "../../assets/images/card_d.png";
import ConferenceRoomImage from "../../assets/images/Card_test.png";


export const VIEWS = {
  membership: "membership",
  services: "services",
};

const meetingRoomCardData = [
  {
    title: "2-6 Person Room",
    prices: [
      { title: "1 hour", price: "30" },
      { title: "Half Day", price: "150" },
      { title: "Full Day", price: "200" },
    ],
  },
  {
    title: "7-10 Person Room",
    prices: [
      { title: "1 hour", price: "40" },
      { title: "Half Day", price: "200" },
      { title: "Full Day", price: "300" },
    ],
  },
];

const conferenceRoomCardData = [
  {
    title: "Up to 40 Person Conference",
    prices: [
      { title: "1 hour", price: "80" },
      { title: "Half Day", price: "300" },
      { title: "Full Day", price: "500" },
    ],
  },
  {
    title: "Up to 80 Person Conference",
    prices: [
      { title: "1 hour", price: "150" },
      { title: "Half Day", price: "400" },
      { title: "Full Day", price: "750" },
    ],
  },
];

const DedicatedDesk = [
  {
    title: "Dedicated Desk",
    prices: [
      { title: "01 week", price: "150" },
      { title: "10 Days", price: "180" },
      { title: "15 Days", price: "250" },
      { title: "01 Month", price: "425" },
    ],
  },
];

export const ratesData = [
  {
    title: "Flex Desk",
    price: "30",
    priceIn: "/day",
    description: "A shared desk that can be booked daily.",
    tag: " #2002",
    image: MobileFlexDeskImage,
    view: VIEWS.membership,
    mobileViewData: {
      title: "MEMBERSHIPS",
      subTitle: "Join Hive on your terms",
      desc: "Whether you’re here for the long run or just passing through. We have plans to suit everyone.",
    },
  },
  {
    title: "Dedicated Desk",
    price: "425",
    priceIn: "/month",
    description: "Fixed desk for the amount of time you need.",
    tag: " #2002",
    image: MobileDedicatedDeskImage,
    hoverData: DedicatedDesk,
    view: VIEWS.membership,
    mobileViewData: {
      title: "MEMBERSHIPS",
      subTitle: "More than meets the Hive",
      desc: "We have spaces for all meeting sizes from private and cosy to large scale indoor and outdoor conferences.",
    },
  },
  {
    title: "Private Office",
    price: "call",
    priceIn: "/month",
    description: "Quiet, secure spaces for long-term use.",
    tag: " #2002",
    image: MobilePrivateOfficeImage,
    view: VIEWS.membership,
    mobileViewData: {
      title: "MEMBERSHIPS",
      subTitle: "More than meets the Hive",
      desc: "We have spaces for all meeting sizes from private and cosy to large scale indoor and outdoor conferences.",
    },
  },
  {
    title: "Meeting Rooms",
    price: "30",
    priceIn: "/hour",
    description: "Cozy room for events and meetings.",
    tag: " #2902",
    image: MeetingRoomImage,
    hoverData: meetingRoomCardData,
    view: VIEWS.services,
    mobileViewData: {
      title: "SERVICES",
      subTitle: "More than meets the Hive",
      desc: "We have spaces for all meeting sizes from private and cosy to large scale indoor and outdoor conferences.",
    },
  },
  {
    title: "Conference Rooms",
    price: "80",
    priceIn: "/day",
    description: "For formal & informal meetings and events.",
    tag: " #2002",
    image: ConferenceRoomImage,
    hoverData: conferenceRoomCardData,
    view: VIEWS.services,
    mobileViewData: {
      title: "SERVICES",
      subTitle: "Join Hive on your terms",
      desc: "Whether you’re here for the long run or just passing through. We have plans to suit everyone.",
    },
  },
];

export const viewData = {
  [VIEWS.membership]: {
    title: "MEMBER-SHIPS",
    subTitle: "Join Hive on your terms",
    desc: "Whether you’re here for the long run or just passing through. We have plans to suit everyone.",
    tag: "",
    buttonText: "services",
  },
  [VIEWS.services]: {
    title: "SERVICES",
    subTitle: "More than meets the Hive",
    desc: "We have spaces for all meeting sizes from private and cosy to large scale indoor and outdoor conferences",
    tag: " #0923",
    buttonText: "memberships",
  },
};