import { ReactComponent as SliderCountBoxIcon } from "../../assets/images/green-quare.svg";
import DoubleArrow from "../../components/icons/doubleArrow/doubleArrow";

const NextButtonWithArrow = ({ children, isArrowLeft=false, onClick }) => (
  <div
    className="next-button-container"
    onClick={onClick}
  >
    <div className="next-button">
      <p className="next-button__txt">{children}</p>
      <SliderCountBoxIcon />
    </div>
    <DoubleArrow isArrowLeft={isArrowLeft} />
  </div>
);

export default NextButtonWithArrow;
