import { ReactComponent as SlideLeftIcon } from "../../assets/images/left-chevron.svg";
import { ReactComponent as SlideRightIcon } from "../../assets/images/right-chevron.svg";
import { ReactComponent as SliderCountBoxIcon } from "../../assets/images/green-square.svg";

import "./swiperControler.css";

const SwiperControler = ({ prevRef, nextRef, children }) => (
  <div className="swiperNavigation">
    <div ref={prevRef}>
      <SlideLeftIcon />
    </div>
    <div className="navigate-next" ref={nextRef}>
      <div className="index">
        {children}
        <SliderCountBoxIcon className="back_box" />
      </div>
      <div>
        <SlideRightIcon />
      </div>
    </div>
  </div>
);

export default SwiperControler;
