import React from "react";
import "./FacilitiesSecondContent.styles.css";
import backImage from "../../assets/images/party.png";
import innerImage from "../../assets/images/basketball.png";
import NextButtonWithArrow from "../nextButtonWithArrow/nextButtonWithArrow";

export const FacSecondData = [
  {
    title: "Roof Top Terrace.",
    code: "",
    description: "Roof Top Terrace. Chillout zone, bar and outdoor event space",
  },
  {
    title: "Basketball Court.",
    code: "",
    description: "Full size rooftop basketball court.",
  },
];

const FacilitiesSecondContent = ({ onClick }) => {
  return (
    <div className="scnd_fac_wrapper">
      <div className="scnd_imageContainer">
        <img src={backImage} alt="" className="scnd_cover" />
        <img src={innerImage} alt="" className="scnd_inner_image" />
      </div>

      <div className="fac_container">
        <h1 className="first_title">WAY UP TOP</h1>
        <span className="first_subtitle">For extra curricular activites</span>
        <ol className="first_list" type="A">
          {FacSecondData.map((each, i) => {
            return (
              <li key={i} className="first_listItem">
                <span>{each.title}</span>
                <span className="first_desc"> {each.description}</span>
                {/* <span> {each.code}</span> */}
              </li>
            );
          })}
        </ol>
        <NextButtonWithArrow onClick={onClick} isArrowLeft>
          Back
        </NextButtonWithArrow>
      </div>
    </div>
  );
};

export default FacilitiesSecondContent;
