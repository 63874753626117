import React, { useState } from "react";
import "./sideBarMenuStyles.css";
import CloseBtnIcon from "../../assets/images/Close.png";
import MenuIcon from "../../assets/images/Menu.png";
import H from "../../assets/images/h.svg";
import VE from "../../assets/images/ve.svg";
import { Link, useNavigate } from "react-router-dom";
import Socials from "../Socials/Socials";
import { ReactComponent as IncativeHomeSvg } from "../../assets/images/inactive-home.svg";
import { ReactComponent as AtiveHomeSvg } from "../../assets/images/home-active.svg";

function SideBarMenu() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  let navigate = useNavigate();

  const toggleMenuHandler = () => {
    setIsMenuOpened((prev) => !prev);
  };

  const handleHomeIconClick = (e) => {
    e.stopPropagation();
    setIsMenuOpened(false);
    navigate("/");
  };

  return (
    <aside
      className={`sideBar ${isMenuOpened && "active"}`}
      onClick={toggleMenuHandler}
    >
      <div className="container">
        <img src={H} alt="H" className="img" />
        <div className={`menu ${isMenuOpened && "active"}`}>
          <div className="burgerMenu">
            <img
              src={isMenuOpened ? CloseBtnIcon : MenuIcon}
              alt="burger-menu"
            />
          </div>
          <nav className="navigation">
            <div className="firstLink">
              <Link to="/facilities" className="navLink">
                FACILITIES
              </Link>
              <div className="nav-home-container" onClick={handleHomeIconClick}>
                <IncativeHomeSvg className="inactive-home" />
                <AtiveHomeSvg className="active-home" />
              </div>
            </div>
            <div className="firstLink">
              <Link to="/location" className="navLink">
                LOCATION
              </Link>
              <div />
            </div>
            <div className="firstLink">
              <Link to="/services" className="navLink">
                SERVICES
              </Link>
              <div />
            </div>
            <div className="lastLink">
              <Link to="/contact" className="navLink">
                CONTACT
              </Link>

              <Socials />
            </div>
          </nav>
        </div>
        <img src={VE} alt="VE" className="img" />
      </div>
    </aside>
  );
}

export default SideBarMenu;
